<template>
  <div class="allCourses">
    <Loading :active.sync="visible"></Loading>
    <div class="tags">
      <div class="tag filter">
        <div class="filter" @click.stop="toggleFilterModal">
          <span>Filter</span>
          <div class="carat" :class="{ open: filterModalOpen }"></div>
        </div>
      </div>
      <div class="tag show">
        <Multiselect v-model="showTag" :options="showOptions" :show-labels="false" :allow-empty="false"></Multiselect>
      </div>
      <div class="filterType">
        <div class="list" @click="filterType = 'list'">
          <ListBlue v-if="filterType === 'list'" />
          <ListBlack v-else />
        </div>
        <div class="grid" @click="filterType = 'grid'">
          <GridBlue v-if="filterType === 'grid'" />
          <GridBlack v-else />
        </div>
      </div>
      <div class="search">
        <SearchSVG class="search-icon" />
        <input type="text" v-debounce:500ms="myFunc" v-model="search" placeholder="Search" />
      </div>
      <transition :name="transitionName">
        <div v-if="filterModalOpen" class="filter-modal" v-click-outside="{ handler: toggleFilterModal }">
          <div class="multiselects">
            <div class="jobs">
              <span>Job Role(s)</span>
              <br />
              <br />
              <Multiselect
                v-model="jobTag"
                :options="jobOptions"
                :show-labels="false"
                :allow-empty="false"
              ></Multiselect>
              <!-- <div class="filter">
              <span>{{ jobsFilter }}</span>
            </div> -->
            </div>
            <div class="instructors">
              <span>Instructor(s):</span>
              <br />
              <br />
              <Multiselect
                v-model="instructorTag"
                :options="instructorOptions"
                :show-labels="false"
                :allow-empty="false"
              ></Multiselect>
              <!-- <div class="filter">
              <span>{{ instructorsFilter }}</span>
            </div> -->
            </div>
          </div>
          <div class="prerequisites-toggle">
            <div
              class="toggle"
              @click="prerequisitesToggle = !prerequisitesToggle"
              :class="{ on: prerequisitesToggle === true }"
            >
              <div class="circle"></div>
            </div>
            <span>No prerequisites</span>
          </div>
          <div class="button">
            <button class="primary-btn" @click="toggleFilterModal">Apply</button>
          </div>
        </div>
      </transition>
    </div>
    <!-- Table View -->
    <div v-if="filterType === 'list'" class="tableContainer">
      <table class="newCourses">
        <tr class="top-row">
          <th>Course Name</th>
          <th>Type</th>
          <th>Who It's For</th>
          <th>Instructor(s)</th>
          <th>Last Modified</th>
          <th>Course</th>
          <th>Prerequisites</th>
          <th v-if="userInfo && userInfo.ILTRoleID && (userInfo.ILTRoleID === 3 || userInfo.ILTRoleID === 4)">
            Status
          </th>
          <th>Actions</th>
        </tr>
        <tr v-for="(course, index) in showFilteredCourses" :key="index">
          <td>{{ course.name }}</td>
          <td
            class="bold"
            :class="[
              getType(course.livestreamService) === 'VC' ? 'bright' : '',
              getType(course.livestreamService) === 'MTT' ? 'red' : '',
            ]"
          >
            {{ getType(course.livestreamService) || '—' }}
          </td>
          <td>{{ getRoles(course.roles).join(', ') || 'All Job Roles' }}</td>
          <td>{{ getInstructors(course.instructors).join(', ') || '—' }}</td>
          <td>{{ getMomentTime(course.updatedAt) || getMomentTime(course.createdAt) || '—' }}</td>
          <td>{{ course.courseNumber }}</td>
          <!-- <td>{{ getPrereqs(course.prereqs).join(', ') || 'None' }}</td> -->
          <td>{{ course.prereqs.join(', ') || '—' }}</td>
          <td
            v-if="userInfo && userInfo.ILTRoleID && (userInfo.ILTRoleID === 3 || userInfo.ILTRoleID === 4)"
            class="status"
          >
            <div class="inner">
              <!-- {{ course.status || 'null' }} -->
              <div class="toggle" :class="{ on: course.status === 'published' }" @click="toggleStatus(index)">
                <div class="circle"></div>
              </div>
              <span v-if="course.status === 'published'">Published</span>
              <span v-else>Draft</span>
            </div>
          </td>
          <!-- <td>prereqs</td> -->
          <td v-if="getType(course.livestreamService) === 'VC'" class="light bold">
            <span></span
            ><span
              v-if="(userInfo && userInfo.ILTRoleID === 3) || (userInfo && userInfo.ILTRoleID === 4)"
              @click="editCourse(course)"
              >Edit Classroom</span
            >
            <span v-else @click="launchCourse(course)">Launch Classroom</span>
          </td>
          <td v-else-if="getType(course.livestreamService) === 'MTT'" class="light bold">
            <span
              v-if="(userInfo && userInfo.ILTRoleID === 3) || (userInfo && userInfo.ILTRoleID === 4)"
              @click="editCourse(course)"
              >Edit Toolkit</span
            >
            <span v-else @click="launchCourse(course)">Launch Toolkit</span>
          </td>
        </tr>
      </table>
    </div>
    <!-- Grid View -->
    <div v-else-if="filterType === 'grid'" class="courseGrid">
      <div v-for="(course, index) in showFilteredCourses" :key="index" class="course">
        <header
          :class="[
            getType(course.livestreamService) === 'VC' ? 'bright' : '',
            getType(course.livestreamService) === 'MTT' ? 'red' : '',
          ]"
        >
          <h1>{{ course.name }}</h1>
          <h2 v-if="getType(course.livestreamService) === 'VC'" class="bright">
            VIRTUAL CLASSROOM
          </h2>
          <h2 v-else-if="getType(course.livestreamService) === 'MTT'" class="red">
            MODULAR TRAINING TOOLKIT
          </h2>
        </header>
        <section>
          <ul>
            <li>Who it's for: {{ getRoles(course.roles).join(', ') || 'All Job Roles' }}</li>
            <li>Instructor(s) {{ getInstructors(course.instructors).join(', ') || '—' }}</li>
            <li>Date: {{ getMomentTime(course.updatedAt) || getMomentTime(course.createdAt) || '—' }}</li>
            <li>Course: {{ course.courseNumber }}</li>
            <!-- <li>Prerequisites: {{ getPrereqs(course.prereqs).join(', ') || 'none' }}</li> -->
            <li>{{ course.prereqs.join(', ') || '—' }}</li>
            <!-- <span
              v-if="userInfo && userInfo.ILTRoleID && (userInfo.ILTRoleID === 3 || userInfo.ILTRoleID === 4)"
              class="status"
            >
              <div
                class="toggle"
                :class="{ on: course.status === 'published' }"
                @click="course.status = !course.status"
              >
                <div class="circle"></div>
              </div>
            </span> -->
          </ul>
        </section>
        <div class="bottom">
          <span
            v-if="userInfo && userInfo.ILTRoleID && (userInfo.ILTRoleID === 3 || userInfo.ILTRoleID === 4)"
            class="status"
          >
            <div class="toggle" :class="{ on: course.status === 'published' }" @click="toggleStatus(index)">
              <div class="circle"></div>
            </div>
            <span>{{ course.status }}</span>
          </span>
          <h1 v-if="getType(course.livestreamService) === 'VC'" class="bright">
            <span
              v-if="(userInfo && userInfo.ILTRoleID === 3) || (userInfo && userInfo.ILTRoleID === 4)"
              @click="editCourse(course)"
              >Edit Classroom</span
            ><span v-else @click="launchCourse(course)">Launch Classroom</span>
          </h1>

          <h1 v-if="getType(course.livestreamService) === 'MTT'" class="bright">
            <span
              v-if="(userInfo && userInfo.ILTRoleID === 3) || (userInfo && userInfo.ILTRoleID === 4)"
              @click="editCourse(course)"
              >Edit Toolkit</span
            ><span v-else @click="launchCourse(course)">Launch Toolkit</span>
          </h1>
        </div>
        <!-- <h1 class="bright">Edit Classroom</h1> -->
      </div>
      <div v-if="numCoursesShown % 3 !== 0" class="course after"></div>
    </div>
    <div class="paginator-container" v-if="showFilteredCourses && instructorFilteredCourses.length > perPage">
      <div class="leftArrow" @click="decrementPage">
        <Arrow />
      </div>
      <Paginate v-model="page" :page-count="numPages" :prev-text="''" :next-text="''" :container-class="'paginator'">
      </Paginate>
      <div class="rightArrow" @click="incrementPage">
        <Arrow />
      </div>
    </div>
    <div v-if="!showFilteredCourses || !showFilteredCourses.length" class="no-content">
      No content to display
    </div>
    <div class="hidden"></div>
    <!-- <footer class="footer">© 2020 Volkswagen Academy</footer> -->
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import SearchSVG from '@/assets/icons/search_icon.svg';
import ListBlack from '@/assets/icons/list_black.svg';
import ListBlue from '@/assets/icons/list_blue.svg';
import GridBlack from '@/assets/icons/grid_black.svg';
import GridBlue from '@/assets/icons/grid_blue.svg';
import Paginate from 'vuejs-paginate';
import Arrow from '@/assets/icons/arrow.svg';
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import Vue from 'vue';
import axios from 'axios';
import vueDebounce from 'vue-debounce';
import { mapGetters } from 'vuex';

Vue.use(vueDebounce);

export default {
  components: {
    Multiselect,
    SearchSVG,
    ListBlack,
    ListBlue,
    GridBlack,
    GridBlue,
    Paginate,
    Arrow,
    Loading,
  },
  data() {
    return {
      page: 1,
      jobTag: 'All Job Roles',
      instructorTag: 'All Instructors',
      showTag: 'Show 5',
      showOptions: ['Show 5', 'Show 10', 'Show 20'],
      filterModalOpen: false,
      filterType: 'list',
      prerequisitesToggle: false,
      search: '',
      visible: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.checkPrevRoute(from.fullPath);
    });
  },
  created() {
    this.visible = true;
    this.$store
      .dispatch('getJobRoles')
      .then(() => {
        this.$store.dispatch('getUsers').then(() => {
          if (this.userInfo.ILTRoleID && this.userInfo.ILTRoleID === 1) {
            this.$store.dispatch('getPublishedCourses');
          } else {
            this.$store.dispatch('getCourses');
          }
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        this.visible = false;
      });
    if (this.userInfo && this.userInfo.ILTRoleID === 3) {
      this.getMyCourses();
    }
    if (this.userInfo && this.userInfo.ILTRoleID === 2) {
      this.getCoursesTaughtByMe();
    }
  },
  methods: {
    checkPrevRoute(prev) {
      if (prev.includes('create-course')) {
        this.$store.dispatch('getCourses').catch((e) => {
          console.error(e);
        });
      }
    },
    toggleFilterModal() {
      this.filterModalOpen = !this.filterModalOpen;
    },
    decrementPage() {
      if (this.page > 1) {
        this.page -= 1;
      }
    },
    incrementPage() {
      if (this.page < this.numPages) {
        this.page += 1;
      }
    },

    getType(service) {
      if (service && service.length) {
        return 'VC';
      }
      return 'MTT';
    },
    getMomentTime(time) {
      if (time === '0000-00-00 00:00:00') {
        return '—';
      }
      return moment.utc(time).format('MM-DD-yy');
    },
    myFunc() {
      this.page = 1;
    },
    getRoles(roles) {
      const x = [];
      if (roles) {
        roles.forEach((role) => {
          x.push(role.name);
        });
      }
      return x;
    },
    getInstructors(instructors) {
      const x = [];
      if (instructors && this.instructors) {
        instructors.forEach((instructor) => {
          const y = this.instructors.findIndex((ins) => ins.id.toString() === instructor.userID.toString());
          if (y > -1) {
            x.push(`${this.instructors[y].firstName} ${this.instructors[y].lastName}`);
          }
        });
      }
      return x;
    },
    // getPrereqs(prereqs) {
    //   const x = [];
    //   if (this.courses && prereqs) {
    //     prereqs.forEach((prereq) => {
    //       const y = this.courses.findIndex((course) => course.courseNumber.toString() === prereq.prereqID.toString());
    //       if (y > -1) {
    //         x.push(this.courses[y].name);
    //       }
    //     });
    //   }
    //   return x;
    // },
    getMyCourses() {
      axios
        .get(`/courses/?author=${this.userInfo.userID}`, {
          headers: { authorization: sessionStorage.getItem('vwcup-token') },
        })
        .then((response) => {
          // handle success
          this.$store.dispatch('getCoursesCreatedByMe', response.data.data.courses);
        })
        .catch((error) => {
          // handle error
          console.error(error);
        });
    },
    getCoursesTaughtByMe() {
      axios
        .get(`/courses/?instructors=${this.userInfo.userID}`, {
          headers: { authorization: sessionStorage.getItem('vwcup-token') },
        })
        .then((response) => {
          // handle success
          this.$store.dispatch('getCoursesTaughtByMe', response.data.data.courses);
        })
        .catch((error) => {
          // handle error
          console.error(error);
        });
    },
    toggleStatus(index) {
      // make axios call to update course status
      const course = this.showFilteredCourses[index];
      if (course.status === 'published') {
        course.status = 'draft';
      } else if (course.status === 'draft') {
        course.status = 'published';
      }
      const roles = [];
      course.roles.forEach((role) => {
        roles.push({ code: role.roleID, name: role.name });
      });
      const instructors = [];
      course.instructors.forEach((instructor) => {
        instructors.push(instructor.userID);
      });
      // const prereqs = [];
      // course.prereqs.forEach((prereq) => {
      //   prereqs.push(prereq.prereqID);
      // });
      const equipment = [];
      course.equipment.forEach((equ) => {
        equipment.push({ name: equ.name, required: equ.required });
      });
      this.visible = true;
      axios
        .put(
          `${process.env.VUE_APP_API_URL}/courses/${course.id}`,
          {
            name: course.name,
            status: course.status,
            roles,
            instructors,
            prereqs: course.prereqs,
            equipment,
            courseNumber: course.courseNumber,
            livestreamService: course.livestreamService,
            livestreamLink: course.livestreamLink,
            livestreamPin: course.livestreamPin,
            livestreamPhone: course.livestreamPhone,
            description: course.description,
            modules: course.modules,
          },
          {
            headers: { authorization: sessionStorage.getItem('vwcup-token') },
          },
        )
        .then(() => {
          this.visible = false;
          // this.$router.push('/all-courses');
        })
        .catch((error) => {
          console.error(error);
          this.visible = false;
        });
    },
    editCourse(course) {
      this.$store.dispatch('setCourseBeingEdited', course).then(() => {
        this.$router.push(`/edit-course/${course.id}`);
      });
    },
    launchCourse(course) {
      this.$store.dispatch('setActiveCourse', course).then(() => {
        this.$router.push(`/course/${course.id}`);
      });
    },
  },
  watch: {
    jobTag() {
      if (this.jobTag === null) {
        this.jobTag = 'All Job Roles';
      }
    },
    instructorTag() {
      if (this.instructorTag === null) {
        this.instructorTag = 'All Instructors';
      }
    },
    showTag() {
      if (this.showTag === null) {
        this.showTag = 'Show 5';
      }
    },
    perPage() {
      this.page = 1;
    },
    prerequisitesToggle() {
      this.page = 1;
    },
    page() {
      // this.getCourses();
    },
  },
  computed: {
    ...mapGetters(['jobRoles', 'instructors', 'courses', 'coursesCreatedByMe', 'userInfo', 'coursesTaughtByMe']),
    transitionName() {
      return this.$mq === 'lg' ? 'scale' : 'slide';
    },
    jobOptions() {
      const x = ['All Job Roles'];
      if (this.jobRoles) {
        this.jobRoles.forEach((role) => {
          x.push(role.name);
        });
      }
      return x;
    },
    instructorOptions() {
      const x = ['All Instructors'];
      if (this.instructors) {
        this.instructors.forEach((instructor) => {
          x.push(`${instructor.firstName} ${instructor.lastName}`);
        });
      }
      return x;
    },
    perPage() {
      if (this.showTag === 'Show 5') {
        return 5;
      }
      if (this.showTag === 'Show 10') {
        return 10;
      }
      if (this.showTag === 'Show 20') {
        return 20;
      }
      return -1;
    },
    searchFilteredCourses() {
      const x = [];
      if (this.userInfo && this.userInfo.ILTRoleID === 3 && this.coursesCreatedByMe) {
        this.coursesCreatedByMe.forEach((course) => {
          if (course.name.toUpperCase().includes(this.search.toUpperCase())) {
            x.push(course);
          }
        });
      } else if (this.userInfo && this.userInfo.ILTRoleID === 2 && this.coursesTaughtByMe) {
        this.coursesTaughtByMe.forEach((course) => {
          if (course.name.toUpperCase().includes(this.search.toUpperCase())) {
            x.push(course);
          }
        });
      } else if (this.courses) {
        this.courses.forEach((course) => {
          if (course.name.toUpperCase().includes(this.search.toUpperCase())) {
            x.push(course);
          }
        });
      }
      return x;
    },
    prereqFilteredCourses() {
      if (!this.prerequisitesToggle) {
        return this.searchFilteredCourses;
      }

      const x = [];
      if (this.searchFilteredCourses) {
        this.searchFilteredCourses.forEach((course) => {
          if (!course.prereqs || course.prereqs.length === 0) {
            x.push(course);
          }
        });
      }
      return x;
    },
    roleFilteredCourses() {
      if (this.jobTag === 'All Job Roles') {
        return this.prereqFilteredCourses;
      }
      const x = [];
      if (this.prereqFilteredCourses) {
        this.prereqFilteredCourses.forEach((course) => {
          course.roles.forEach((role) => {
            if (role.name === this.jobTag) {
              x.push(course);
            }
          });
        });
      }
      return x;
    },
    instructorFilteredCourses() {
      if (this.instructorTag === 'All Instructors') {
        return this.roleFilteredCourses;
      }
      const x = [];
      if (this.roleFilteredCourses) {
        this.roleFilteredCourses.forEach((course) => {
          course.instructors.forEach((instructor) => {
            if (instructor.userID === this.instructorID) {
              x.push(course);
            }
          });
        });
      }
      return x;
    },
    showFilteredCourses() {
      let x = [];
      if (this.instructorFilteredCourses) {
        if (this.numCourses <= this.perPage) {
          x = this.instructorFilteredCourses;
        } else {
          x = this.instructorFilteredCourses.slice(
            this.perPage * (this.page - 1),
            this.perPage * (this.page - 1) + this.perPage,
          );
        }
      }
      return x;
    },
    numPages() {
      if (this.perPage > this.numCourses) {
        return 1;
      }
      return Math.ceil(this.numCourses / this.perPage);
    },
    numCourses() {
      if (this.searchFilteredCourses) {
        return this.searchFilteredCourses.length;
      }
      return 0;
    },
    instructorID() {
      let x = -1;
      if (this.instructors && this.instructorTag && this.instructorTag !== 'All Instructors') {
        const y = this.instructors.findIndex((ins) => `${ins.firstName} ${ins.lastName}` === `${this.instructorTag}`);
        x = this.instructors[y].id;
      }
      return x;
    },
    numCoursesShown() {
      if (this.showFilteredCourses.length < this.perPage) {
        return this.showFilteredCourses.length;
      }
      return this.perPage;
    },
  },
};
</script>

<style lang="scss" scoped>
.allCourses {
  display: flex;
  flex-direction: column;
  .tags {
    height: 100%;
    /* IE 10+ Styles */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 54px;
    }

    // flex: 3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // max-width: 500px;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    @include md {
      width: 100%;
    }
    > .tag {
      margin-bottom: 14px;
      width: 210px;
      // flex: 1;
      // border: 1px solid #fff;
      height: 40px;
      border-radius: 3px;
      line-height: 40px;
      margin-right: 15px !important;
      @include md {
        margin: 0 auto 14px 0;
      }
      &.filter {
        width: 100%;
        @include md {
          width: 210px;
        }
      }
      .arrow {
        width: 15px;
        position: absolute;
        margin-top: 15px;
        // margin-left: 75px;
        &.flip {
          transform: scaleY(-1);
        }
      }
      .filter {
        text-align: left;
        height: 40px !important;
        padding: 0 0 0 15px !important;
        border: 1px solid $light-grey;
        cursor: pointer;
        color: #000;
        background: #fff;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;

        @include lg {
          // line-height: 1.125;
          border: 1px solid #dfe4e8;
          // border-radius: 3px;
          padding: 7px 55px 9px 15px;
          height: auto;
        }
        span {
          vertical-align: middle;
          font-size: 1.6rem;
          font-family: $head-font-stack;
          font-weight: 300;
        }
        .carat {
          width: 16px;
          background: url('~@/assets/icons/carat.svg?external') center center no-repeat;
          transition: transform 300ms;
          margin-right: 15px;
          &.open {
            transform: rotate(180deg);
            transition: transform 300ms;
          }
        }
        .icon-badge {
          margin-left: 5px;
          vertical-align: middle;
          font-size: 14px;
          background: #00b1eb !important;
          color: white !important;

          @include lg {
            margin-left: 10px;
          }
        }
        &.moveLeft {
          &::after {
            margin-left: 102px;
          }
        }
      }
      &.show {
        width: 50% !important;
        @include md {
          width: 124px !important;
        }
      }
    }
    > .filterType {
      display: flex;
      align-items: center;
      width: 60px;
      margin: 0 17px 14px auto;
      @include md {
        margin-right: 26px;
      }
      > .list {
        margin: 0 0 0 auto;
        width: 24px;
        cursor: pointer;
        &.activeFilterType {
          color: $primary-light;
        }
      }
      > .grid {
        margin: 0 0 0 auto;
        width: 20px;
        cursor: pointer;
        &.activeFilterType {
          color: $primary-light;
        }
      }
    }
    > .search {
      width: calc(100% - 17px);
      border: 1px solid #fff;
      height: 40px;
      border-radius: 3px;
      line-height: 40px;
      display: flex;
      background: #fff;
      border: 1px solid $light-grey;
      margin-bottom: 14px;
      @include md {
        width: 240px;
      }
      > input {
        height: 100%;
        width: calc(100% - 35px);
        padding: 0;
        border: none;
        padding: 0 15px;
        color: #000 !important;
        outline: none;
        height: calc(100% - 2px);
        margin-top: 1px;
        font-weight: 300;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: #000;
          opacity: 0.5;
          font-weight: 300;
        }
      }
      .search-icon {
        width: 15px;
        margin-left: 10px;
      }
    }
  }
  > .tableContainer {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.29);
    > table {
      margin-top: 20px;
      width: 100%;
      font-size: 1.2rem;
      border-collapse: collapse;
      // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.29);
      overflow: scroll;
      display: block;
      @include md {
        display: table;
        overflow: hidden;
        margin-top: 0;
      }
      > .top-row {
        font-size: 1.4rem;
        background: $light-grey;
        height: 50px;
        > th {
          text-align: left;
          padding: 0 14px;
          font-family: $head-font-stack;
          font-weight: 300;
        }
      }
      > tr {
        > td {
          height: 60px;
          text-align: left;
          padding: 0px 14px;
          word-wrap: break-word;
          font-weight: 300;
          &.light {
            color: $primary-light;
            > span {
              cursor: pointer;
            }
          }
          &.bold {
            font-weight: 700;
          }
          &.bright {
            color: $primary-bright;
          }
          &.red {
            color: $danger;
          }
          &.status {
            text-transform: capitalize;
            > .inner {
              display: flex;
              align-items: center;
              > .toggle {
                width: 35px;
                height: 14px;
                background: $grey;
                border-radius: 50px;

                margin-right: 10px;
                cursor: pointer;
                > .circle {
                  background: $primary-white;
                  height: 12px;
                  width: 12px;
                  border-radius: 50%;
                  border: 1px solid $grey;
                  transition: margin 0.3s ease-in-out;
                }
                &.on {
                  background: $primary-light;
                  > .circle {
                    margin-left: 21px;
                    border-color: $primary-light;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  table td,
  table th {
    border: 2px solid $light-grey;
  }
  table tr:first-child th {
    border-top: 0;
  }
  table tr:last-child td {
    border-bottom: 0;
  }
  table tr td:first-child,
  table tr th:first-child {
    border-left: 0;
  }
  table tr td:last-child,
  table tr th:last-child {
    border-right: 0;
  }
  > .courseGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > .course {
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.29);
      display: flex;
      flex-direction: column;
      min-height: 280px;
      width: 80%;

      margin: 20px auto 0 auto;
      @include md {
        width: calc(50% - 12px);
        margin: 0 0 24px 0;
      }
      @include mdl {
        width: calc(33% - 12px);
        margin: 0 0 24px 0;
      }
      > header {
        // height: 90px;
        background: $primary-dark;
        color: #fff;
        > h1 {
          padding: 35px 25px 5px 25px;
          margin: 0;
          font-size: 1.8rem;
        }
        > h2 {
          padding: 0px 25px 18px 25px;
          margin: 0;
          font-size: 1.2rem;
          &.bright {
            color: $primary-bright;
          }
          &.red {
            color: $danger;
          }
        }
        &.bright {
          border-bottom: 5px solid $primary-bright;
        }
        &.red {
          border-bottom: 5px solid $danger;
        }
      }
      > section {
        padding: 25px 25px 15px 25px;
        font-size: 1.2rem;
        font-weight: 300;
        > ul {
          list-style: none;
          padding: 0;
          margin: 0;
          > li {
            padding-bottom: 10px;
            &.status {
              text-transform: capitalize;
              > .toggle {
                width: 35px;
                height: 14px;
                background: $grey;
                border-radius: 50px;
                display: flex;
                align-items: center;
                margin-right: 10px;
                cursor: pointer;
                > .circle {
                  background: $primary-white;
                  height: 12px;
                  width: 12px;
                  border-radius: 50%;
                  border: 1px solid $grey;
                  transition: margin 0.3s ease-in-out;
                }
                &.on {
                  background: $primary-light;
                  > .circle {
                    margin-left: 21px;
                    border-color: $primary-light;
                  }
                }
              }
            }
          }
        }
      }
      h1 {
        font-weight: 700;
        &.bright {
          color: $primary-light;
          font-size: 1.2rem;
          // padding: 0 25px;
          padding: 0;
          margin: 0;
          margin-bottom: 25px;
          cursor: pointer;
        }
      }
      h2 {
        font-weight: 700;
      }
      .bottom {
        display: flex;
        padding: 0 25px;
        .status {
          flex: 1;
          display: flex;
          text-transform: capitalize;
          font-family: $head-font-stack;
          font-weight: 300;
          font-size: 1.2rem;
          > .toggle {
            width: 35px;
            height: 14px;
            background: $grey;
            border-radius: 50px;
            display: flex;
            align-items: center;
            margin-right: 7px;
            cursor: pointer;
            > .circle {
              background: $primary-white;
              height: 12px;
              width: 12px;
              border-radius: 50%;
              border: 1px solid $grey;
              transition: margin 0.3s ease-in-out;
            }
            &.on {
              background: $primary-light;
              > .circle {
                margin-left: 21px;
                border-color: $primary-light;
              }
            }
          }
        }
      }
    }
    .after {
      @include md {
        content: '';
        opacity: 0;
        pointer-events: 0;
        flex: auto;
        max-width: calc(33% - 12px);
      }
    }
    &.full {
      .after {
        display: none;
      }
    }
  }
  > .paginator-container {
    margin-top: 44px;
    color: #00000050;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 20px;
    }
    height: 24px;
    > .leftArrow {
      width: 12px;
      height: 100%;
      line-height: 30px;
      margin-right: 65px;
      opacity: 0.5;
      cursor: pointer;
    }
    > .rightArrow {
      width: 12px;
      height: 100%;
      line-height: 30px;
      margin-left: 65px;
      transform: rotate(180deg);
      opacity: 0.5;
      cursor: pointer;
    }
    > .paginator {
      font-size: 14px;
      list-style: none;
      display: flex;
      padding: 0;
      // ::v-deep {
      // .active {
      //   a {
      //     color: #000 !important;
      //   }
      // }
      // li {
      //   margin: 0 10px !important;
      // }
      // }
    }
    ::v-deep {
      a {
        outline: none;
      }
    }
  }
  .hidden {
    flex: auto;
  }
}
.filter-modal {
  color: #000;
  z-index: 100;
  width: calc(100% - 17px);

  @include md {
    margin-top: 70px !important;
    // border: 1px solid white;
    background: #fff;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.29);
    border: 1px solid $light-grey;
    border-radius: 0px 3px 3px 3px;
    margin-top: -1px;
    padding: 32px 35px 45px 35px;
    position: absolute;
    // top: 179px;
    top: 112px;
    // left: 75px;
    left: calc(20vw + 67px);
    width: auto;
    min-width: 492px;
    height: auto;

    > .multiselects {
      display: flex;
      margin-bottom: 25px;
      > .jobs {
        margin-right: 25px;
        flex: 1;
        > span {
          font-family: $head-font-stack;
          font-weight: 700;
        }
        .filter {
          font-family: $head-font-stack;
          font-weight: 300;
          text-align: left;
          height: 40px !important;
          padding: 0 0 0 15px !important;
          border: 1px solid $light-grey;
          cursor: pointer;
          color: #000;
          background: #fff;
          border-radius: 3px;
          width: 195px;

          @include lg {
            // line-height: 1.125;
            border: 1px solid #dfe4e8;
            // border-radius: 3px;
            padding: 7px 55px 9px 15px;
            height: auto;

            &::before {
              content: '';
              background: url('~@/assets/icons/carat.svg?external') center center no-repeat;
              background-size: 16px 8px;
              border: 0;
              margin: -4px 0 0 -8px;
              position: absolute;
              top: 50%;
              left: 50%;
              width: 16px;
              height: 8px;
            }
            &.open::after {
              transform: rotate(180deg) translateY(50%);
            }
          }
          span {
            vertical-align: middle;
            line-height: 40px;
            font-size: 1.6rem;
          }
          .icon-badge {
            margin-left: 5px;
            vertical-align: middle;
            font-size: 14px;
            background: #00b1eb !important;
            color: white !important;

            @include lg {
              margin-left: 10px;
            }
          }
          &.moveLeft {
            &::after {
              margin-left: 102px;
            }
          }
        }
      }
      > .instructors {
        flex: 1;
        > span {
          font-family: $head-font-stack;
          font-weight: 700;
        }
        .filter {
          font-family: $head-font-stack;
          font-weight: 300;
          text-align: left;
          height: 40px !important;
          padding: 0 0 0 15px !important;
          border: 1px solid $light-grey;
          cursor: pointer;
          color: #000;
          background: #fff;
          border-radius: 3px;
          width: 195px;

          @include lg {
            // line-height: 1.125;
            border: 1px solid #dfe4e8;
            // border-radius: 3px;
            padding: 7px 55px 9px 15px;
            height: auto;

            &::before {
              content: '';
              background: url('~@/assets/icons/carat.svg?external') center center no-repeat;
              background-size: 16px 8px;
              border: 0;
              margin: -4px 0 0 -8px;
              position: absolute;
              top: 50%;
              left: 50%;
              width: 16px;
              height: 8px;
            }
            &.open::after {
              transform: rotate(180deg) translateY(50%);
            }
          }
          span {
            vertical-align: middle;
            line-height: 40px;
            font-size: 1.6rem;
          }
          .icon-badge {
            margin-left: 5px;
            vertical-align: middle;
            font-size: 14px;
            background: #00b1eb !important;
            color: white !important;

            @include lg {
              margin-left: 10px;
            }
          }
          &.moveLeft {
            &::after {
              margin-left: 102px;
            }
          }
        }
      }
    }
    .prerequisites-toggle {
      font-size: 1.4rem;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      > .toggle {
        width: 35px;
        height: 14px;
        background: $grey;
        border-radius: 50px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        cursor: pointer;
        > .circle {
          background: $primary-white;
          height: 12px;
          width: 12px;
          border-radius: 50%;
          border: 1px solid $grey;
          transition: margin 0.3s ease-in-out;
        }
        &.on {
          background: $primary-light;
          > .circle {
            margin-left: 21px;
            border-color: $primary-light;
          }
        }
      }
      > span {
        font-family: $head-font-stack;
        font-weight: 300;
      }
    }
    > .button {
      cursor: pointer;
      > .primary-btn {
        margin: 0 0 0 auto;
      }
    }
  }
  .filter-list {
    border-top: 1px solid $light-grey;
    @media only screen and (max-width: 1000px) {
      margin-top: 75px;
    }
    ::v-deep {
      h3 {
        padding-left: 20px;
        color: black !important;
      }
      label {
        color: black;
      }
      label::before {
        border: 1px solid black;
      }
      .icon-badge {
        font-size: 14px;
        background: #00b1eb !important;
        color: white !important;
      }
      @include lg {
        border-top: 0;

        h3 {
          font-size: 22px;
          border: 0;
          box-shadow: none;
          margin-bottom: 15px;
          padding: 0;
          background: transparent;
          font-weight: normal;
          color: white;

          span {
            vertical-align: baseline;
          }
          &::after {
            content: ':';
            background: none;
            position: static;
            vertical-align: baseline;
          }
        }
        .icon-badge {
          display: none;
        }
        .collapsible-content {
          background: none;
          box-shadow: none;
          max-height: none;
          padding: 0;
          // text-transform: uppercase;
        }
      }
    }
  }
  // .filter-list h3,
  .filter-toggles h3,
  .filter-toggles ::v-deep label {
    font-size: 1.6rem;
    font-weight: 300;
    display: block;
    margin: 0;
    padding: 22px 20px 26px 28px;
    position: relative;
    z-index: 2;

    @include lg {
      font-size: 2rem;
      border: 0;
      box-shadow: none;
      padding: 0;
    }
    .icon-badge {
      margin-top: 0.125em;
      margin-left: 10px;
      position: absolute;

      @include lg {
        display: none;
      }
    }
  }
  .filter-list {
    @include lg {
      // + .filter-list {
      //   margin-left: 50px;
      // }
    }
    ul {
      margin: 0;
      padding: 20px 0;
      position: relative;
      z-index: 1;
      overflow: hidden;

      @include lg {
        background: none;
        padding: 0;
      }
      li {
        padding: 0 20px 0 28px;

        @include lg {
          padding: 0;
        }
      }
      .checkbox {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .filter-toggles {
    @include lg {
      margin-left: 50px;
    }
    h3 {
      display: none;

      @include lg {
        display: block;
        margin-bottom: 15px;

        &::after {
          content: ':';
        }
      }
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        @include lg {
          padding: 10px 0;
        }
      }
    }
    ::v-deep {
      label {
        @include lg {
          font-size: inherit;
          padding-left: 70px;
        }
        span {
          position: relative;

          @include lg {
            position: static;
          }
        }
      }
    }
  }
}
.applied-filters {
  list-style-type: none;
  // flex-basis: 100%;
  // text-transform: uppercase;
  margin: 0;
  padding: 0px;
  // padding: 55px 15px 0;

  @include lg {
    margin-left: 15px;
    padding: 0;
    flex-basis: auto;
  }
  > span {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    cursor: pointer;
    font-size: 1.2rem;
    background: transparent url('../../assets/icons/x_icon.svg?external') no-repeat right 10px center;
    border: 1px solid white;
    color: white;
    background-size: 10px;
    border-radius: 2px;
    display: inline-block;
    margin: 0 7px 10px;
    padding: 4px 37px 10px 10px;
    min-height: 21px;

    @include lg {
      margin-bottom: 0;
    }

    // & + li {
    //   margin-left: 14px;
    // }
  }
}
.prerequisites-toggle {
  font-size: 1.4rem;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  > .toggle {
    width: 35px;
    height: 14px;
    background: $grey;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    > .circle {
      background: $primary-white;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 1px solid $grey;
      transition: margin 0.3s ease-in-out;
    }
    &.on {
      background: $primary-light;
      > .circle {
        margin-left: 21px;
        border-color: $primary-light;
      }
    }
  }
  > span {
    font-family: $head-font-stack;
    font-weight: 300;
  }
}
.jobs,
.instructors {
  margin-bottom: 14px;
  @include md {
    margin-bottom: 0;
  }
}
.no-content {
  width: 100%;
  height: 450px;
  background: $primary-white;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
